import React from "react";
import "./navbar.css";
const navbar = () => {
  return (
    <div className="container">
      <nav className="navbar navbar-expand-lg">
        <div className="container-fluid">
          <p>
            <span className="heading">P</span>
            <span className="heading-green">ORTFOLIO</span>
          </p>
        </div>
        <div className="d-flex">
          <a className="i-nav-link">Home</a>
          <a className="i-nav-link">Blog</a>
          <a className="i-nav-link">Skills</a>
          <a className="i-nav-link">Project</a>
        </div>
      </nav>
    </div>
  );
};

export default navbar();
