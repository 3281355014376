import React, { useState } from "react";
import axios from "axios";
import "./contact.css";

const baseURL = "https://www.sushantkumarsinha.com/contact";

const Contact = (props) => {
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    setFullName("");
    setEmail("");
    setPhoneNumber("");
    setMessage("");
  };

  const sendContactInfo = (event) => {
    axios
      .post(baseURL, {
        name: fullName,
        email: email,
        phone: phoneNumber,
        message: message,
      })
      .then((response) => {
        console.log(response);
      });

    event.preventDefault();
    setFullName("");
    setEmail("");
    setPhoneNumber("");
    setMessage("");
  };

  return (
    <div className="page-size">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <span>
              <span className="i-text">
                <span id="contact" className="white-text">
                  Contact
                </span>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row contact-content">
          <div className="col-md-6">
            <div className="contact-field">
              <img className="location-icon" src="images/location.svg"></img>
              <span className="location-text">Bengaluru, India</span>
            </div>
            <div className="contact-field">
              <img className="location-icon" src="images/mail.svg"></img>
              <span className="location-text">
                sushantkumarsinha22@gmail.com
              </span>
            </div>
            <div className="contact-field">
              <img className="location-icon" src="images/linkedin.svg"></img>
              <span className="location-text">
                linkedin.com/in/sushant-kumar-sinha/
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <h1>Get in touch with me</h1>
            <form>
              <div className="full-width">
                <input
                  onChange={(event) => setFullName(event.target.value)}
                  value={fullName}
                  className="form-input"
                  placeholder="Full name"
                ></input>
              </div>
              <div className="full-width">
                <div className="half-width phone-number-div">
                  <input
                    onChange={(event) => setPhoneNumber(event.target.value)}
                    value={phoneNumber}
                    className="form-input"
                    placeholder="Phone Number"
                  ></input>
                </div>
                <div className="half-width email-div">
                  <input
                    onChange={(event) => setEmail(event.target.value)}
                    value={email}
                    className="form-input"
                    placeholder="Email"
                  ></input>
                </div>
              </div>
              <div>
                <textarea
                  onChange={(event) => setMessage(event.target.value)}
                  value={message}
                  className="full-width form-input"
                  placeholder="Your message"
                ></textarea>
              </div>
              <div className="full-width">
                <button onClick={sendContactInfo} className="submit-button">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="ending-text centered-text">
            <span>
              Made with <img class="heart" src="images/heart.png" /> by Sushant
              Kumar
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
