import "./App.css";
import landing from "./components/body/landing";
import navbar from "./components/navbar/navbar";
import companies from "./components/body/companies";
import achievements from "./components/body/achievements";
import Projects from "./components/body/projects";
import Contact from "./components/body/contact";
function App() {
  return (
    <div>
      {navbar}
      {landing}
      {companies}
      {achievements}
      {<Projects />}
      {<Contact />}
    </div>
  );
}

export default App;
