import React from "react";
import "./landing.css";
const landing = () => {
  const downloadResume = () => {
    var resumeUrl = process.env.REACT_APP_URL + "/files/resume.pdf";
    window.open(resumeUrl, "_blank").focus();
  };

  const hireme = () => {
    var protocol = window.location.protocol;
    var current_location = window.location.host;
    window.location.href = protocol + "//" + current_location + "/#contact";
  };

  return (
    <div className="container">
      <div className="row page-size">
        <div className="banner-text col md-6">
          <h1 className="landing-headline-1">Hi! I'm Sushant Kumar</h1>
          <h1 className="landing-headline-2">Software Engineer</h1>
          <p>
            Software Engineer with 2+ years of experience in backend development
            and full-stack development using Java, Nodejs, and React. I have
            done some work on Machine learning and Deep learning as well. I love
            solving real life problems.
          </p>
          <p>
            <button className="hire-me-button" onClick={hireme}>
              Hire Me
            </button>
            <button
              className="explore-projects-button"
              onClick={downloadResume}
            >
              Download Resume
            </button>
          </p>
        </div>
        <div className="col md-6">
          <img src="images/banner.png"></img>
        </div>
      </div>
    </div>
  );
};

export default landing();
