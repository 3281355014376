import React from "react";
import "./projects.css";
const Projects = () => {
  return (
    <div className="page-size">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <span>
              <span className="i-text">
                <span className="white-text">Projects</span>
              </span>
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="project-tile project-tile-bottom-border-blue">
              <img className="project-img" src="images/counterfiet.png"></img>
              <p className="project-text">Counterfiet note detection</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className=" project-tile project-tile-bottom-border-red">
              <img className="project-img" src="images/temporal.png"></img>
              <p className="project-text">
                Temporal concept localisation in videos
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="project-tile project-tile-bottom-border-purple">
              <img className="project-img" src="images/myst.png"></img>
              <p className="project-text">E-commerce website</p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="project-tile project-tile-bottom-border-purple">
              <img className="project-img" src="images/solveberry.png"></img>
              <p className="project-text">
                Problem solving and rewarding platform for chidlren
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className=" project-tile project-tile-bottom-border-blue">
              <img className="project-img" src="images/tackll.png"></img>
              <p className="project-text">
                Enterprise learning platform through gamification
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="project-tile project-tile-bottom-border-red">
              <img className="project-img" src="images/rope.png"></img>
              <p className="project-text">
                Rope implementation using splay tree
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
