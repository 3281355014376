import React from "react";
import "./companies.css";
const companies = () => {
  return (
    <div className="page-size">
      <div className="row">
        <div className="col-md-12">
          <span>
            <img src="images/companies-graphic.png"></img>
            <span className="i-text">
              <span className="green-text">Companies </span>
              <span className="white-text">I have worked for</span>
            </span>
          </span>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="tile tile-bottom-border-blue">
              <img src="/images/ola-logo.png"></img>
              <p className="company-text">
                Working on all the suport related products provided to the
                customers and to the call center agents for smooth resolution of
                problems in Ola cabs and Ola electrtic. If you are raising a
                ticket on <b>ola cabs/electric</b> you are probably interacting
                with my code.
              </p>
              <p className="date-text">September 2021 - Present</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="tile tile-bottom-border-red">
              <img src="/images/fivetran-logo.png"></img>
              <p className="company-text">
                Worked with fivetran to deliver data from multiple source to
                different destination like Snowflake, Redshift, BigQuery and
                many more. Worked as the primary owner of Salesforce data
                pipeline made it more reliable and
                <b> improved the performance by atleast 10%</b>. Worked on
                several other projects as well.
              </p>
              <p className="date-text">January 2020 - September 2021</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="tile tile-bottom-border-purple">
              <img src="/images/isro-logo.png"></img>
              <p className="company-text">
                Worked on an internal project to do the landcover classification
                of data from <b>HysIS Satellite</b> using
                <b> CNN, RNN and Random Forest with an efficiency of 85%</b>.
                Built a python based GUI similar to ARC GIS for a better
                visualization of the satellite image after classification.
              </p>
              <p className="date-text">March 2019 - May 2019</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default companies();
