import React from "react";
import "./achievements.css";
const achievements = () => {
  return (
    <div className="page-size">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <span>
              <span className="i-text">
                <span className="white-text">Achievements</span>
              </span>
            </span>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-4 achivement-tile">
            <img
              className="achievement-img"
              src="images/hackwithinfy.png"
            ></img>
            <span className="achievement-text">
              Won Hackwithinfy 2019 a 3 round coding contest and hackathon
              arranged by Infosys Ltd. amongst 118K+ participants from all over
              India.
            </span>
          </div>
          <div className="col-md-4 achivement-tile">
            <img className="achievement-img" src="images/sih.png"></img>
            <span className="achievement-text">
              Got selected in the top 4 teams for Smart India Hackathon Software
              Edition 2019 for the problem statement set by NCR Corpoation.
            </span>
          </div>
          <div className="col-md-4 achivement-tile">
            <img className="achievement-img" src="images/codechef.png"></img>
            <span className="achievement-text">
              Achieved Global Rank 35 in CodeChef Long Challenge 2019
            </span>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-4 achivement-tile">
            <img className="achievement-img" src="images/kickstart.png"></img>
            <span className="achievement-text">
              Secured Global Ranks 507, 867 in Google Kick-Start Rounds 2019.
            </span>
          </div>
          <div className="col-md-4 achivement-tile">
            <img className="achievement-img" src="images/ijhs.png"></img>
            <span className="achievement-text">
              Publised a research paper on temporal concept localisation in
              videos in International Journal of Health Sciences.
            </span>
          </div>
          <div className="col-md-4 achivement-tile">
            <img className="achievement-img" src="images/fivetran.png"></img>
            <span className="achievement-text">
              Won Fivetran spot award for working as the primary owner of
              Salesforce data pipeline and improving its performance by a
              significant amount.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default achievements();
